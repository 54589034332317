import React, { useState, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { Alert } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/Info';

import { TYPE_OF_CHANGE, TYPE_OF_CHANGE_CHOICE_GROUP } from '../../../../App/common/constant';
import ModalAppBar from '../../../components/ProductDetail/ModalAppBar';
import ProductDetailMenuPhoto from '../../../components/ProductDetailMenuPhoto';
import HighLightBox from '../../../components/HighlightBox';
import StatusBox from '../../../components/StatusBox';
import Form from './ProductDetailForm';
import { modalStyles, alertStyles } from '../../../components/ProductDetail/styles';
import { capitalizeFirstLetter } from '../../../../utility/information';
import useProductDetails from '../../useProductDetails';
import { renderChangeDetails } from '../../ProductHelpers';

const TYPES_OF_CHANGE_TO_SHOW_MENU_PHOTO = new Set([
  TYPE_OF_CHANGE_CHOICE_GROUP.CHOICE_GROUP_CREATE,
  TYPE_OF_CHANGE_CHOICE_GROUP.CHOICES_CREATE,
  TYPE_OF_CHANGE_CHOICE_GROUP.CHOICES_ADD,
  TYPE_OF_CHANGE.PRODUCT_CREATE,
  TYPE_OF_CHANGE.VARIANT_CREATE,
  TYPE_OF_CHANGE_CHOICE_GROUP.CHOICES_PRICE_CHANGE,
  TYPE_OF_CHANGE.VARIANT_UNITPRICE_CHANGE,
]);

const STYLE_APP_BAR_WITH_MENU_PHOTO = { marginLeft: 'auto', width: '50%' };

const useStyles = makeStyles(modalStyles);
const useAlertStyles = makeStyles(alertStyles);

const ProductDetailModal = ({
  vats,
  open,
  isSaving,
  handleClose,
  handleSave,
  categories,
  choiceGroups,
  cgData,
  choiceGroupAPIStatus,
  fwfFlags,
  currentCountry,
  changeItem,
  isAgentAssignedToVendor,
  historyMode,
  isMQSProduct,
}) => {
  const [shouldShowMenuPhoto, setShouldShowMenuPhoto] = useState(false);
  const [changeWithImage, setChangeWithImage] = useState(null);

  useEffect(() => {
    const isFeatureFlagOn = !!fwfFlags?.fwfProductDetailMenuPhoto?.variation;

    const changeList = changeItem.changes;
    const relevantChange = changeList.find((change) =>
      TYPES_OF_CHANGE_TO_SHOW_MENU_PHOTO.has(change.item.typeOfChange)
    );

    setShouldShowMenuPhoto(isFeatureFlagOn && !!relevantChange);
    setChangeWithImage(relevantChange);
  }, [changeItem, fwfFlags]);

  const product = changeItem.item;
  const changeRequests = changeItem.changes;
  const allChangeTypes = changeRequests.map((c) => c.item.typeOfChange);

  const classes = useStyles();
  const alertClasses = useAlertStyles();

  let status = 'pending';
  if (historyMode && changeItem.changes[0]?.item?.status) {
    status = changeItem.changes[0]?.item?.status.toLowerCase();
  }

  const {
    isPrepacked,
    category,
    vatId,
    variants,
    handleTitleDescUpdate,
    handleCategoryUpdate,
    handlePrepackedUpdate,
    handlePriceUpdate,
    handleVariationNameUpdate,
    handleVatUpdate,
    updateChoiceGroups,
    getPatchData,
    compiledTitle,
    compiledDescription,
  } = useProductDetails(product, changeRequests, cgData);

  const handleSubmit = () => {
    const patchDataAccumulator = getPatchData();

    handleSave(patchDataAccumulator);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableAutoFocus={true}
      data-enzyme={'ProductDetailModal'}
    >
      <Box data-cp="product-detail-modal" height="100%" width="100%">
        <ModalAppBar
          onClose={handleClose}
          handleSave={handleSubmit}
          isFormDirty={true}
          isSaving={isSaving}
          isAgentAssignedToVendor={isAgentAssignedToVendor}
          style={shouldShowMenuPhoto ? undefined : STYLE_APP_BAR_WITH_MENU_PHOTO}
        />
        <Box display="flex" height="calc(100% - 64px)">
          {shouldShowMenuPhoto && (
            <ProductDetailMenuPhoto
              flex="0 0 50%"
              menuPhoto={changeWithImage?.meta?.menuPhoto}
              vendorCode={
                changeItem?.changes?.length > 0 ? changeItem.changes[0].item.vendorCode : ''
              }
              currentCountry={currentCountry}
              width="50%"
              fwfFlags={fwfFlags}
            />
          )}
          <Box
            flex="0 0 50%"
            overflow="scroll"
            bgcolor="white"
            className={classes.root}
            width="50%"
            ml="auto"
          >
            {!isAgentAssignedToVendor && (
              <Alert
                className={alertClasses.alert}
                severity="info"
                data-enzyme={'ProductDetailModalInfoText'}
                icon={<InfoIcon fontSize="inherit" />}
              >
                View-only mode.
                {!historyMode && 'Assign yourself to this vendor so you can process this request.'}
              </Alert>
            )}
            <Box marginY={3}>
              <HighLightBox type={status}>
                <Box display="flex" p={1}>
                  <Box flex={1} justifyContent="center" fontSize={18} lineHeight="28px">
                    {capitalizeFirstLetter(status)} Requests
                  </Box>
                  <Box flex={0}>
                    <StatusBox type={status} />
                  </Box>
                </Box>
                {changeRequests.map((request) =>
                  renderChangeDetails(request, product, vats, categories, currentCountry?.currency)
                )}
              </HighLightBox>
            </Box>
            <Box m={3}>
              <Form
                applicableChangeTypes={allChangeTypes}
                vats={vats}
                vatId={vatId.value}
                title={compiledTitle}
                description={compiledDescription}
                category={category.value}
                variants={Object.values(variants)}
                isPrepacked={isPrepacked.value}
                product={product}
                categories={categories}
                cgData={cgData}
                choiceGroupAPIStatus={choiceGroupAPIStatus}
                changeItem={changeItem}
                vendorChoiceGroups={choiceGroups}
                fwfFlags={fwfFlags}
                currentCountry={currentCountry}
                handleTitleDescUpdate={handleTitleDescUpdate}
                handleCategoryUpdate={handleCategoryUpdate}
                handleVatUpdate={handleVatUpdate}
                handlePriceUpdate={handlePriceUpdate}
                handleVariationNameUpdate={handleVariationNameUpdate}
                handlePrepackedUpdate={handlePrepackedUpdate}
                updateChoiceGroups={updateChoiceGroups}
                isAgentAssignedToVendor={isAgentAssignedToVendor}
                historyMode={historyMode}
                productPhoto={isMQSProduct ? changeItem.item.images[0] : undefined}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductDetailModal;
