import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Typography, Checkbox, makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import _ from 'lodash';
import moment from 'moment';

import { getDefaultLanguage } from '../../../../utility/information';
import { brandProxyApiHeader, getChanges } from '../../../utility/information';

import styles from './styles';
import { TYPE_OF_CHANGE } from '../../../../App/common/constant';
import { ProductDetailModal } from './index';
import axios from '../../../../App/utility/axios';
import config from '../../../../App/config';
import { AppContext, UserContext } from '../../../../App';
import { useSnackbar } from 'notistack';
import { getApprovalQueryInputFromPatchData } from '../NewChoiceGroup/helpers';

const useStyles = makeStyles(styles);

export const formatTimestamp = (timestamp) => {
  return moment(timestamp, 'X').format('DD MMM YYYY, H:mm');
};

const Product = ({
  categories,
  choiceGroups,
  vatList,
  cgData,
  choiceGroupAPIStatus,
  showCheckbox,
  checked,
  onCheck,
  onSaveChanges,
  fwfFlags,
  productId,
  changeArray,
  isMQSProduct,
  mqsProductDetails,
}) => {
  const classes = useStyles();
  const currentUser = React.useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [isProductDetailModalOpen, setIsProductDetailModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  //If new product created, always only one change item
  const isCreated = changeArray[0].item.typeOfChange === TYPE_OF_CHANGE.PRODUCT_CREATE;
  const timestamp = changeArray[0].item.timestamp;

  const { currentCountry } = React.useContext(AppContext);
  const currency = currentCountry.currency;

  const [product, setProduct] = useState({});
  const [productApierror, setProductApierror] = useState(false);
  const [productApiIsLoading, setProductApiIsLoading] = useState(false);

  const transformKeys = (obj) => {
    const transformed = {};
    for (const [key, value] of Object.entries(obj)) {
      const newKey = key.split('_')[0];
      transformed[newKey] = value;
    }
    return transformed;
  };

  const transformedMQSProduct = useMemo(() => {
    if (isMQSProduct && !!mqsProductDetails) {
      return {
        ...mqsProductDetails,
        variants: [mqsProductDetails.variants],
        description: transformKeys(mqsProductDetails.description),
        title: transformKeys(mqsProductDetails.title),
        category: {
          id: mqsProductDetails.category_id,
        },
      };
    }
  }, [mqsProductDetails, isMQSProduct]);

  //Fetch product from MSS brand proxy endpoint
  useEffect(() => {
    if (isMQSProduct) {
      setProduct(transformedMQSProduct);
    } else if (!!productId && !productId?.startsWith('NEW')) {
      setProductApiIsLoading(true);
      try {
        axios(`${config.brandProxyApi}/product/${productId}`, {
          headers: brandProxyApiHeader(currentCountry, changeArray),
        }).then((response) => {
          setProduct(response?.data?.data);
          setProductApiIsLoading(false);
        });
      } catch (error) {
        console.log(error);
        setProductApiIsLoading(false);
        setProductApierror(true);
      }
    }
  }, [productId, changeArray, currentCountry, isMQSProduct, transformedMQSProduct]);

  const onSaveProductDetails = (patchData) => {
    setIsSaving(true);
    const input = getApprovalQueryInputFromPatchData({ patchData, currentUser });

    onSaveChanges({
      variables: {
        input: input,
      },
    }).then(() => {
      enqueueSnackbar(`Successfully saved  request`, {
        variant: 'success',
      });
      setIsProductDetailModalOpen(false);
      setIsSaving(false);
    });
  };

  const onProductClick = () => {
    setIsProductDetailModalOpen(true);
  };

  const getTitle = useCallback(() => {
    const isCategory = changeArray.find((c) => c.item.typeOfChange.startsWith('Category'));

    const createChange = changeArray.find(
      (c) => c.item.typeOfChange === TYPE_OF_CHANGE.PRODUCT_CREATE
    );

    let title;

    const productNotFoundInApiResponse =
      _.isEmpty(product) && !createChange && !productApiIsLoading && !productApierror;

    if (isCategory && productNotFoundInApiResponse) {
      return;
    }

    if (!!createChange) {
      title = createChange.item.patchData.title;
    } else if (!_.isEmpty(product)) {
      //Product object exists
      if (!_.isEmpty(product?.title)) {
        //title
        title = product?.title;
      } else {
        title = { en: product?.defaultTitle };
      }
    } else if (productNotFoundInApiResponse) {
      title = { en: 'Product no longer available. Please reject these changes.' };
    } else if (productApierror) {
      title = { en: 'Failed to get item data' };
    } else {
      title = { en: 'Loading...' };
    }

    return title[getDefaultLanguage(title)];
  }, [product, changeArray, productApiIsLoading, productApierror]);

  const onChangesCheck = (allChanges) => (evt) => {
    if (evt.target.checked) {
      onCheck((prev) => {
        return _.union(prev, allChanges);
      });
    } else {
      onCheck((prev) => _.difference(prev, allChanges));
    }
  };

  const renderChange = (change) => {
    const originalData = change.item.content.data;
    const patchData = change.item.patchData;

    const typeOfChange = _.get(change, 'item.typeOfChange', 'UnrecognizedChange');

    const [oldValue, newValue] = getChanges(
      typeOfChange,
      originalData,
      patchData,
      product,
      categories,
      vatList,
      currency
    );

    return (
      <Box component={'span'} p={1} key={change.requestId} data-enzyme={'change-container'}>
        {typeOfChange}
        <Typography
          className={`${classes.lineThrough} ${classes.changeValuesOld}`}
          data-enzyme={'old-value'}
        >
          {oldValue || '-'}
        </Typography>
        <Typography variant={'body2'} className={classes.changeValuesNew} data-enzyme={'new-value'}>
          {newValue || '-'}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Box
        mb={1}
        p={0}
        border={1}
        display={'flex'}
        flexWrap={'wrap'}
        alignItems={'baseline'}
        borderColor={`${isCreated ? green[600] : 'grey.300'}`}
        onClick={onProductClick}
        data-enzyme={`product-${productId}`}
        data-cp={`product-${productId}`}
      >
        <Box component={'span'} p={1}>
          {showCheckbox && (
            <Checkbox
              checked={checked}
              onClick={(e) => {
                //Just to prevent triggering the modal when clicking on checkbox
                e.stopPropagation();
              }}
              onChange={onChangesCheck(changeArray)}
            />
          )}
        </Box>

        <Box component={'span'} p={1} className={classes.labelContainer}>
          <Typography className={classes.label} data-enzyme={'title'}>
            {getTitle()}
          </Typography>
        </Box>

        {timestamp && (
          <Box component={'span'} p={1} className={classes.labelContainer}>
            <Typography className={classes.labelTimestamp} data-enzyme={'timestamp'}>
              {formatTimestamp(timestamp)}
            </Typography>
          </Box>
        )}

        {changeArray.map((change) => {
          return renderChange(change);
        })}
      </Box>
      {isProductDetailModalOpen && (
        <ProductDetailModal
          vats={vatList}
          open={isProductDetailModalOpen}
          isSaving={isSaving}
          handleClose={() => {
            setIsProductDetailModalOpen(false);
          }}
          handleSave={onSaveProductDetails}
          changeItem={{
            item: isMQSProduct ? transformedMQSProduct : product,
            changes: changeArray,
          }}
          categories={categories}
          choiceGroups={choiceGroups}
          cgData={cgData}
          choiceGroupAPIStatus={choiceGroupAPIStatus}
          isAgentAssignedToVendor={showCheckbox}
          fwfFlags={fwfFlags}
          currentCountry={currentCountry}
          historyMode={false}
          isMQSProduct={isMQSProduct}
        />
      )}
    </>
  );
};

export default Product;
