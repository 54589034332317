import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ProductSection from '../../../components/ProductDetail/ProductSection';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import produce from 'immer';
import t from '../../../../utility/Translate';
import styled from 'styled-components';
import { Grid, Box, FormHelperText } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import _merge from 'lodash/merge';
import countryToCodeAndSymbol, { codeToSymbol } from '../../../utility/translation';
import { getDefaultLanguage } from '../../../../utility/information';
import { TYPE_OF_CHANGE } from '../../../../App/common/constant';
import { AppContext } from '../../../../App';
import ProductChoiceGroups from './ProductDetailFormComponents/ProductChoiceGroups';
import { useSelector } from 'react-redux';
const styles = (theme) => ({
  selectWrapper: {
    marginTop: 20,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  commonTextField: {
    flex: '1 1 0%',
    '& + &': {
      marginLeft: '16px',
    },
  },
  prepackedMessage: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
    fontWeight: 'normal',
    height: '64px',
    letterSpacing: '0.2px',
    lineHeight: '16px',
    width: '100%',
    marginTop: '19px',
  },
  prepackedLabel: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    height: '25px',
    lineHeight: '25px',
    width: '100%',
  },
  prepackageWrapper: {
    marginTop: '32px',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
  },
  prepackageCheckbox: {
    height: '18px',
    width: '18px',
    margin: '0px 15px 0px 11px',
  },
});

const STYLE_IMG_WITH_MENU_PHOTO_URL = {
  backgroundColor: '#a7a7a7',
  flex: '0 0 100%',
  width: '-webkit-fill-available',
  marginTop: 4,
  objectFit: 'contain',
};

export const VariantsWrapper = styled(Grid)`
  display: flex;
  width: 100%;

  ${(props) => props.theme.breakpoints.down('xs')} {
    flex-flow: row wrap;
  }
`;

const NormalTextField = styled(TextField)`
  width: 100%;
`;
const priceTypeContent = {
  width: '100%',
  float: 'left',
  margin: '15px 0 -3px',
  display: 'flex',
};

const priceType = {
  color: '#000',
  fontSize: '10px',
  float: 'left',
  margin: '0 0 -13px 0',
  fontWeight: 'bold',
  flex: '1 1 0%',
};

export const TitleTextField = styled(NormalTextField)``;

export const DescTextField = styled(NormalTextField)``;

const useStyles = makeStyles(styles);

const { TITLE_CHANGE, DESCRIPTION_CHANGE, CATEGORY_CHANGE, PRODUCT_CREATE, PREPACKED_CHANGE } =
  TYPE_OF_CHANGE;

const ProductDetailsForm = ({
  vats,
  categories,
  product,
  vendorChoiceGroups,
  title: productTitle,
  description,
  category,
  cgData,
  choiceGroupAPIStatus,
  changeItem,
  variants,
  vatId,
  isPrepacked,
  handleTitleDescUpdate,
  handleCategoryUpdate,
  handlePriceUpdate,
  handleVariationNameUpdate,
  handlePrepackedUpdate,
  handleVatUpdate,
  updateChoiceGroups,
  applicableChangeTypes,
  isAgentAssignedToVendor,
  newChoiceGroup,
  historyMode,
  readonly = false,
  productPhoto = undefined,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fwfState = useSelector((state) => state.fwfState);
  const fwfFlags = fwfState.flags;
  const { currentCountry } = React.useContext(AppContext);

  const hasValueChanged = (changeTypes = []) => {
    return applicableChangeTypes.some((changeType) => changeTypes.includes(changeType));
  };

  const currency = currentCountry.currency;
  const hasVariants = !!variants?.length;
  const categoriesObj = keyBy(categories, 'id');
  const initialMasterCategory = categoriesObj[category]?.masterCategory?.title;

  const isTitleChanged =
    isAgentAssignedToVendor &&
    fwfFlags.fwfProductDetailTitleField.variation &&
    hasValueChanged([PRODUCT_CREATE, TITLE_CHANGE]);
  const isDescriptionChanged =
    isAgentAssignedToVendor &&
    fwfFlags.fwfProductDetailDescField.variation &&
    hasValueChanged([PRODUCT_CREATE, DESCRIPTION_CHANGE]);
  const isCategoryChange =
    isAgentAssignedToVendor &&
    (fwfFlags.fwfProductDetailCategoryField.variation || hasValueChanged([CATEGORY_CHANGE]));
  const isPrepackedChange =
    isAgentAssignedToVendor &&
    (fwfFlags.fwfProductDetailPrepackedField.variation ||
      hasValueChanged([PRODUCT_CREATE, PREPACKED_CHANGE]));
  const languageKeys = countryToCodeAndSymbol[currentCountry.code].map((country) => country.code);

  const getVariantChoiceGroupAssociationsChange = () => {
    let association = {};
    if (applicableChangeTypes.includes(TYPE_OF_CHANGE.PRODUCT_CREATE)) {
      for (let i = 0; i < variants.length; i++) {
        association[i] = {
          addedChoiceGroups: {},
          deletedChoiceGroups: {},
        };
      }
    }
    return association;
  };
  const variantChoiceGroupAssoc = getVariantChoiceGroupAssociationsChange(product);

  const [masterCategory, setMasterCategory] = useState(initialMasterCategory);
  const [masterCategoryMissing, setMasterCategoryMissing] = useState(
    initialMasterCategory ? '' : t('menu_management_pandora.missing_master_category')
  );
  const [variantsChoiceGroup, setVariantsChoiceGroup] = useState(variants || []);
  const [isChoiceGroupChanged, setIsChoiceGroupChanged] = useState(false);

  useEffect(() => {
    setVariantsChoiceGroup((prevState) => {
      const prevVariants = keyBy(prevState, 'id');
      const newVariants = keyBy(variants, 'id');

      const combined = _merge({}, newVariants, prevVariants);

      let newCombinedData = [];
      Object.values(combined).forEach((combinedData) => {
        if (
          combinedData.hasOwnProperty('choice_groups') ||
          combinedData.hasOwnProperty('choiceGroups')
        ) {
          newCombinedData.push(combinedData);
        }
      });
      return newCombinedData;
    });
  }, [variants, changeItem, cgData]);

  useEffect(() => {
    if (isChoiceGroupChanged) {
      updateChoiceGroups(variantsChoiceGroup);
      setIsChoiceGroupChanged(false);
    }
  }, [variantsChoiceGroup, isChoiceGroupChanged, updateChoiceGroups]);

  const onCategoryChange = (evt) => {
    setMasterCategoryMissing('');
    const { value } = evt.target;
    let masterCategoryObj = categoriesObj[value]?.masterCategory?.title;

    if (!masterCategoryObj) {
      setMasterCategoryMissing(t('menu_management_pandora.missing_master_category'));
      masterCategoryObj = t('menu_management_pandora.master_category');
    }
    setMasterCategory(masterCategoryObj);
    handleCategoryUpdate(evt);
  };

  const onChoiceGroupChange = (e, choiceGroupIndex, variantIndex) => {
    // get choice group and get its id
    const choiceGroup = vendorChoiceGroups[choiceGroupIndex];

    // to toggle the UI state, the checkedstate boolean logic of <VariantChoiceGroup> is here
    // https://github.com/deliveryhero/fp-apollo-components/blob/d5af9e2e8d6d4280c479286ae5c38281c73f2042/src/components/VariantChoiceGroupAssociationSection/VariantChoiceGroupAssociationSection.tsx#L55
    const selectedChoiceGroupIds = variantsChoiceGroup[variantIndex]?.choiceGroupIds;
    if (selectedChoiceGroupIds.includes(choiceGroup.id)) {
      setVariantsChoiceGroup(
        produce((draft) => {
          draft[variantIndex].choiceGroupIds = selectedChoiceGroupIds.filter(
            (x) => x !== choiceGroup.id
          );
        })
      );
    } else {
      setVariantsChoiceGroup(
        produce((draft) => {
          draft[variantIndex].choiceGroupIds.push(choiceGroup.id);
        })
      );
    }
    setIsChoiceGroupChanged(true);
  };

  return (
    <>
      <ProductSection title={'Item Details'} data-enzyme={'ProductDetailForm'}>
        {productPhoto && (
          <div>
            <img alt="Product" src={productPhoto} style={STYLE_IMG_WITH_MENU_PHOTO_URL} />
          </div>
        )}
        <div>
          {languageKeys.map((language) => {
            return (
              <TitleTextField
                key={language}
                label={`Item Name [${codeToSymbol[language]}]`}
                value={productTitle[language] ?? ''}
                name={`title`}
                margin="normal"
                onChange={(e) => handleTitleDescUpdate(e, language)}
                disabled={!isTitleChanged || readonly}
                inputProps={{
                  'data-hj-whitelist': true,
                  'data-enzyme': `title_${language}`,
                  'data-cp': `title_${language}`,
                }}
                spellCheck={true}
              />
            );
          })}
        </div>
        <div>
          {languageKeys.map((language) => {
            return (
              <DescTextField
                key={language}
                name={`description`}
                label={`Item Description [${codeToSymbol[language]}]`}
                value={description[language] ?? ''}
                margin="normal"
                fullWidth
                onChange={(e) => handleTitleDescUpdate(e, language)}
                multiline={true}
                spellCheck={true}
                inputProps={{
                  'data-hj-whitelist': true,
                  'data-enzyme': `description_${language}`,
                  'data-cp': `description_${language}`,
                }}
                disabled={!isDescriptionChanged || readonly}
              />
            );
          })}
        </div>
        {categories && (
          <div className={classes.selectWrapper}>
            <FormControl className={classes.selectWrapper} disabled={!isCategoryChange}>
              <InputLabel>Item Category</InputLabel>
              <Select
                value={category}
                onChange={onCategoryChange}
                input={<Input name="category" data-cp="category" data-enzyme="category" />}
                data-cp="product-detail-modal-category"
                disabled={readonly}
              >
                {categories.map((category) => (
                  <MenuItem value={category.id} key={category.id} data-enzyme={'category-option'}>
                    {category.title[getDefaultLanguage(category.title)]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        {masterCategory && (
          <FormControl className={classes.selectWrapper} data-test-id="masterCategory-dropdown">
            <Select
              disabled
              // Fallback message when there is no master category
              value={masterCategory || t('menu_management_pandora.master_category')}
              input={
                <Input
                  name="masterCategory"
                  data-cp="masterCategory"
                  data-enzyme="masterCategory"
                />
              }
              data-cp="product-detail-modal-masterCategory"
            >
              <MenuItem value={masterCategory}>{masterCategory}</MenuItem>
            </Select>
            <FormHelperText>{masterCategoryMissing}</FormHelperText>
          </FormControl>
        )}
        {vats && (
          <div className={classes.selectWrapper} data-test-id="vat-dropdown-section">
            <FormControl className={classes.selectWrapper}>
              <InputLabel>Item VAT</InputLabel>
              <Select
                value={vatId}
                onChange={handleVatUpdate}
                input={<Input name="vatId" data-cp="vat" data-enzyme="vat" />}
                data-cp={'product-detail-modal-category'}
                disabled={readonly}
              >
                {vats.map((vat) => (
                  <MenuItem value={vat.id} key={vat.id}>
                    {vat.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        {(isPrepacked || isPrepacked === false) && (
          <div className={classes.prepackageWrapper}>
            <FormControlLabel
              data-cp={'isPrepacked checkbox'}
              disabled={!isPrepackedChange || readonly}
              className={classes.prepackedLabel}
              control={
                <Checkbox
                  className={classes.prepackageCheckbox}
                  checked={isPrepacked}
                  onChange={handlePrepackedUpdate}
                  name="isPrepacked"
                  color="primary"
                />
              }
              label={t('menu_management_pandora.prepackaged')}
            />
            <Box className={classes.prepackedMessage}>
              {t('menu_management_pandora.prepackaged_message')}
            </Box>
          </div>
        )}
      </ProductSection>
      {hasVariants && (
        <ProductSection
          title={'Prices and Sizes'}
          desc={'does this item come in multiple sizes?  '}
        >
          {variants.map((variant, index) => {
            const { unitPrice, containerPrice, id, title, defaultTitle, modifiedFields } = variant;

            const allowEditing =
              fwfFlags.fwfProductDetailPricesAndSizesField.variation && isAgentAssignedToVendor;
            const isTitleChanged =
              modifiedFields?.some((f) => f === 'create' || f === 'title') || false;
            const isUnitPriceChanged =
              modifiedFields?.some((f) => f === 'create' || f === 'unit_price') || false;
            const isContainerPriceChanged =
              modifiedFields?.some((f) => f === 'create' || f === 'container_price') || false;

            return (
              <div>
                <div style={priceTypeContent}>
                  <span style={priceType}>Unit Price</span>
                  <span style={priceType}>Container Price</span>

                  {!isEmpty(title)
                    ? languageKeys.map((language) => <span style={priceType}></span>)
                    : !!defaultTitle && <span style={priceType}></span>}
                </div>

                <VariantsWrapper theme={theme} key={id || `variant_change_${index}`}>
                  <TextField
                    key={`unitPrice_${id}`}
                    name="unitPrice"
                    label="Unit Price"
                    value={unitPrice}
                    margin="normal"
                    onChange={(evt) => handlePriceUpdate(evt, variant)}
                    onBlur={(e) => handlePriceUpdate(e, variant, 0)}
                    className={classes.commonTextField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{currency.code}</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      'data-cp': `productdetail_price_${id}`,
                      'data-enzyme': `productdetail_price_${id}`,
                    }}
                    disabled={
                      !isUnitPriceChanged ||
                      !allowEditing ||
                      !(isUnitPriceChanged && allowEditing) ||
                      readonly
                    }
                  />

                  <TextField
                    key={`containerPrice_${id}`}
                    name="containerPrice"
                    label="Container Price"
                    value={containerPrice}
                    margin="normal"
                    onChange={(e) => handlePriceUpdate(e, variant)}
                    onBlur={(e) => handlePriceUpdate(e, variant, 0)}
                    className={classes.commonTextField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{currency.code}</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      'data-cp': `productdetail_containerPrice_${id}`,
                      'data-enzyme': `productdetail_containerPrice_${id}`,
                    }}
                    disabled={!(isContainerPriceChanged && allowEditing) || readonly}
                  />
                  {!isEmpty(title)
                    ? languageKeys.map((language) => (
                        <TextField
                          key={`variant_name_${language}`}
                          inputProps={{
                            'data-cp': `variant_name_${language}_${id}`,
                            'data-enzyme': `variant_name_${language}_${id}`,
                          }}
                          name="title"
                          label={`Size name [${codeToSymbol[language]}]`}
                          value={title[language]}
                          margin="normal"
                          className={classes.commonTextField}
                          disabled={
                            !isTitleChanged ||
                            !allowEditing ||
                            !(isTitleChanged && allowEditing) ||
                            readonly
                          }
                          onChange={(e) => handleVariationNameUpdate(e, variant, language)}
                        />
                      ))
                    : !!defaultTitle && (
                        <TextField
                          inputProps={{
                            'data-enzyme': `variant_defaultTitle_${id}`,
                          }}
                          name={'defaultTitle'}
                          label={`Size name`}
                          value={defaultTitle}
                          margin="normal"
                          className={classes.commonTextField}
                          disabled={true}
                        />
                      )}
                </VariantsWrapper>
              </div>
            );
          })}
        </ProductSection>
      )}

      <ProductChoiceGroups
        product={product}
        variants={variantsChoiceGroup}
        vendorChoiceGroups={vendorChoiceGroups}
        choiceGroupAPIStatus={choiceGroupAPIStatus}
        onChoiceGroupChange={onChoiceGroupChange}
        variantChoiceGroupAssociation={variantChoiceGroupAssoc}
        languageKeys={languageKeys}
        isAgentAssigned={isAgentAssignedToVendor}
        fwfFlags={fwfFlags}
        newChoiceGroupData={newChoiceGroup}
      />
    </>
  );
};

export default ProductDetailsForm;
